const productColumns = {
    serialNumber: {
        label: '編號',
        type: 'number',
        position: 'center',
        width: 6,
        widthPx: 70
    },
    supplier: {
        label: '保險公司',
        type: 'string',
        position: 'center',
        width: 0,
        widthPx: 0
    },
    productName: {
        label: '商品名稱',
        type: 'string',
        position: 'center',
        width: 20,
        widthPx: 200
    },
    rfyp: {
        label: '首年保費(NTD)',
        type: 'number',
        position: 'right',
        width: 10,
        widthPx: 130,
        rowNumber: 1
    },
    commissionTotal: {
        label: '商品佣獎總計',
        type: 'number',
        position: 'right',
        width: 10,
        widthPx: 130,
        rowNumber: 2
    },
    onSaleDate: {
        label: '上架日',
        type: 'date',
        position: 'center',
        width: 10,
        widthPx: 120,
        rowNumber: 1
    },
    offSaleDate: {
        label: '停售日',
        type: 'date',
        position: 'center',
        width: 10,
        widthPx: 120,
        rowNumber: 2
    },
    year: {
        label: '年期',
        type: 'number',
        position: 'center',
        width: 10,
        widthPx: 100,
        unit: '年期',
        rowNumber: 1
    },
    age: {
        label: '投保年齡',
        type: 'number',
        position: 'center',
        width: 10,
        widthPx: 100,
        unit: '歲',
        rowNumber: 2
    },
    commission: {
        label: '佣獎',
        position: 'right',
        width: 10,
        widthPx: 100
    },
    total: {
        label: '總計',
        type: 'number',
        position: 'right',
        width: 10,
        widthPx: 130
    },
    fyp: {
        label: '首 年',
        widthPx: 130,
        position: 'right'
    }
}

const productColumnsSetting = {
    receive: [
        'serialNumber',
        'supplier',
        'productName',
        'rfyp',
        'commissionTotal',
        'onSaleDate',
        'offSaleDate',
        'year',
        'age',
        'commission',
        'total',
        'fyp'
    ],
    contract: [
        'serialNumber',
        'supplier',
        'productName',
        'rfyp',
        'commissionTotal',
        'onSaleDate',
        'offSaleDate',
        'year',
        'age',
        'commission',
        'total',
        'fyp'
    ]
}

const productReceiveFilterTitles = {
    supplierCode: '保險公司',
    productName: '商品名稱',
    onSaleDate: '上架日',
    fyp: '保費',
    yPeriod: '年期',
    insAge: '投保年齡',
    _seq: [
        'supplierCode',
        'productName',
        'onSaleDate',
        'fyp',
        'yPeriod',
        'insAge'
    ]
}

const productContractFilterTitles = {
    startDate: '查詢工作月(起)',
    endDate: '查詢工作月(迄)',
    _seq: ['startDate', 'endDate']
}

const productSummaryFilterTitles = {
    supplierCode: '保險公司',
    productName: '商品名稱',
    dateType: '依上架日/停售日',
    onSaleDateStart: '上架日(起)',
    onSaleDateEnd: '上架日(迄)',
    offSaleDateStart: '停售日(起)',
    offSaleDateEnd: '停售日(迄)',
    _seq: [
        'supplierCode',
        'productName',
        'dateType',
        'onSaleDateStart',
        'onSaleDateEnd',
        'offSaleDateStart',
        'offSaleDateEnd'
    ]
}

const productSummaryColumns = {
    supplier: {
        label: '保險公司',
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    productName: {
        label: '商品名稱',
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 150
    },
    onSaleDate: {
        label: '上架日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    offSaleDate: {
        label: '停售日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    bonusType: {
        label: '佣金/\n服務津貼',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '1=佣金 2=服務津貼',
                mobile: '「佣金/服務津貼」1=佣金 2=服務津貼'
            }
        }
    },
    yearPeriodLower: {
        label: '年期下限',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '躉繳=1、彈性繳=1、ALL=1',
                mobile: '「年期下限」躉繳=1、彈性繳=1、ALL=1'
            }
        }
    },
    yearPeriodUpper: {
        label: '年期上限',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '躉繳=1、彈性繳=1、ALL=50',
                mobile: '「年期上限」躉繳=1、彈性繳=1、ALL=50'
            }
        }
    },
    payType: {
        label: '繳別',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 90,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop:
                    'M=月、Q=季、S=半年、Y=年、D=躉、DD=彈性繳、ALL=全部(彈性繳除外)',
                mobile:
                    '「繳別」M=月、Q=季、S=半年、Y=年、D=躉、DD=彈性繳、ALL=全部(彈性繳除外)'
            }
        }
    },
    ageLower: {
        label: '投保年齡\n下限',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: 'ALL=0',
                mobile: '「投保年齡下限」ALL=0'
            }
        }
    },
    ageUpper: {
        label: '投保年齡\n上限',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: 'ALL=99',
                mobile: '「投保年齡上限」ALL=99'
            }
        }
    },
    renewalAgeUpper: {
        label: '續保年齡\n上限',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '不填=99',
                mobile: '「續保年齡上限」不填=99'
            }
        }
    },
    invPremiumType: {
        label: '投資型商品\n目標或超額',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 120,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '0=未分、1=目標、2=超額',
                mobile: '「投資型商品目標或超額」0=未分、1=目標、2=超額'
            }
        }
    },
    invFypLower: {
        label: '投資型商品\n保費下限',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    invFypUpper: {
        label: '投資型商品\n保費上限',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    isYearEnd: {
        label: '是否年終',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '0=無 1=有',
                mobile: '「是否年終」0=無 1=有'
            }
        }
    },
    is13Persistency: {
        label: '是否13個月\n繼續率',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 120,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '0=無 1=有',
                mobile: '「是否13個月繼續率」0=無 1=有'
            }
        }
    },
    is25Persistency: {
        label: '是否25個月\n繼續率',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 120,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '0=無 1=有',
                mobile: '「是否25個月繼續率」0=無 1=有'
            }
        }
    },
    is37Persistency: {
        label: '是否37個月\n繼續率',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 120,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '0=無 1=有',
                mobile: '「是否37個月繼續率」0=無 1=有'
            }
        }
    },
    extendedRule: {
        label: '特殊規則',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: `A = 前一年之比率至繳費期滿<br/>
                B = 前一年之比率至滿期（續保年齡上限）<br/>
                N = 當年度及爾後都沒有續期佣金或服務津貼`,
                mobile: `「特殊規則」欄位內容說明：\nA = 前一年之比率至繳費期滿\nB = 前一年之比率至滿期（續保年齡上限）\nN = 當年度及爾後都沒有續期佣金或服務津貼`
            }
        }
    },
    commissionRate1: {
        label: '首年佣金率%\n(1)',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 110
    }
}

const productDescription = [
    '目前商品僅新光美滿富貴外幣利率變動型終身壽險(2020/12/1上架)有維護 37 個月繼續率獎金',
    '繼續率獎金率以各家公文規定為主，如部分商品有其他規則則暫不納入考慮'
]

export {
    productColumns,
    productColumnsSetting,
    productReceiveFilterTitles,
    productSummaryFilterTitles,
    productSummaryColumns,
    productContractFilterTitles,
    productDescription
}
