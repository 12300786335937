<template>
    <Table
        ref="table"
        :isLoading="isLoading"
        :isPagination="true"
        :isFocusRow="true"
        :isFixedHeader="true"
        :rows="tableShowData"
        :columnConfig="tableColumnConfig"
        :isUseCard="false"
        :descriptions="descriptions"
    >
        <template #supplier>
            <div></div>
        </template>
        <template #productName="{ isHeader, row }">
            <div v-if="isHeader">保險公司<br />商品名稱</div>
            <div v-if="!isHeader">
                <div>{{ row.supplier }}</div>
                <div>{{ row.productName }}</div>
            </div>
        </template>
        <template #commission="{ isHeader }">
            <div v-if="!isHeader" class="commission-column">
                <div class="commission">首續年佣金</div>
                <div class="service">服務津貼</div>
                <div class="continue">繼續率</div>
            </div>
        </template>
        <template #total="{ isHeader, row }">
            <div v-if="!isHeader" class="commission-column">
                <div class="commission">
                    {{ transformNumber(row.fybRyb?.subtotal) }}
                </div>
                <div class="service">
                    {{ transformNumber(row.sab?.subtotal) }}
                </div>
                <div class="continue">
                    {{ transformNumber(row.persistency?.subtotal) }}
                </div>
            </div>
        </template>
        <template #fyp="{ isHeader, row }">
            <div v-if="isHeader" class="fyp-column">
                <div v-for="num in maxPeriod" :key="num">
                    {{ num === 1 ? `首 年` : `${num} 年` }}
                </div>
            </div>
            <div v-else class="fyp-column">
                <div
                    v-for="num in maxPeriod"
                    :key="num"
                    class="commission-column"
                >
                    <div class="commission">
                        {{
                            transformNumber(
                                row.fybRyb?.policyYear
                                    ? row.fybRyb?.policyYear[num]?.bonus
                                    : null
                            )
                        }}
                    </div>
                    <div class="service">
                        {{
                            transformNumber(
                                row.sab?.policyYear
                                    ? row.sab?.policyYear[num]?.bonus
                                    : null
                            )
                        }}
                    </div>
                    <div class="continue">
                        {{
                            transformNumber(
                                row.persistency?.policyYear
                                    ? row.persistency?.policyYear[num]?.bonus
                                    : null
                            )
                        }}
                        <span
                            v-if="
                                row.persistency?.policyYear &&
                                row.persistency?.policyYear[num]?.rate
                            "
                            class="percentage"
                        >
                            {{
                                $roundX(
                                    row.persistency?.policyYear[num]?.rate,
                                    2
                                )
                            }}%
                        </span>
                    </div>
                </div>
            </div>
        </template>
        <template #table-footer>
            <div v-if="!isLoading" class="table-footer">
                <Excel
                    class="excel-btn"
                    :fileName="excelTitle"
                    :disabled="isLoading"
                    :filters="null"
                    :columns="excelColumn"
                    :rows="excelRows"
                    :merge="excelMerge"
                />
                <div class="total-summary">
                    <div class="first">
                        <span>首續年佣金總計：</span>
                        <span>{{
                            transformNumber(totalData.fybRybTotal, true)
                        }}</span>
                    </div>
                    <div class="service">
                        <span>服務津貼總計：</span>
                        <span>{{
                            transformNumber(totalData.sabTotal, true)
                        }}</span>
                    </div>
                    <div class="continue">
                        <span>繼續率獎金總計：</span>
                        <span>{{
                            transformNumber(totalData.persistencyTotal, true)
                        }}</span>
                    </div>
                </div>
            </div>
        </template>
    </Table>
</template>
<script>
import _ from 'lodash'
import Table from '@/components/Table.vue'
import {
    productColumns,
    productColumnsSetting,
    productDescription
} from '@/assets/javascripts/product/setting'
import Excel from '@/components/Excel.vue'

export default {
    name: 'ProductTable',
    components: { Table, Excel },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        totalData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        excelTitle: {
            type: String,
            default: ''
        },
        filterTitles: {
            type: Object,
            default: function () {
                return {}
            }
        },
        filterLog: {
            validator: (value) => {
                return Array.isArray(value) || typeof value === 'object'
            },
            default: function () {
                return []
            }
        }
    },
    methods: {
        transformData(rows) {
            return rows.map((row, idx) => {
                row.commissionTotal = this.transformNumber(row.total, true)
                row.serialNumber = idx + 1
                row.fybRyb = row.detail.fybRyb
                row.sab = row.detail.sab
                row.persistency = row.detail.persistency
                row.productName = row.name
                row.rfyp = row.fyp
                row.year = row.yPeriod
                row.age = row.insAge
                row.offSaleDate = row.offSaleDate || '-'
                return row
            })
        },
        transformNumber(num, allowZero = false) {
            if (num) {
                let n = this.$roundX(num, 0)
                return this.$numberWithComma(n)
            }
            if (allowZero && num === 0) return num
            return '-'
        }
    },
    computed: {
        tableColumnConfig() {
            let config = _.cloneDeep(productColumns)
            const setting = productColumnsSetting
            config = _.pick(config, setting[this.page])
            const widthPx = config.fyp.widthPx
            config.fyp.widthPx = this.maxPeriod * widthPx
            return config
        },
        tableShowData() {
            if (this.listData.length === 0) return []
            let dataset = _.cloneDeep(this.listData)
            dataset = this.transformData(dataset)
            return dataset
        },
        excelColumn() {
            let cols = _.cloneDeep(productColumns)
            const setting = productColumnsSetting
            cols = _.pick(cols, setting[this.page])
            for (let i = 2; i <= this.maxPeriod; i++) {
                let key = `fyp${i}`
                cols[key] = { label: `${i} 年`, position: 'right' }
            }
            cols = _.map(cols, (val, key) => {
                return { key, ...val, isVisible: true }
            })
            return cols
        },
        excelFilter() {
            const titles = this.filterTitles
            let result = {}
            if (Array.isArray(this.filterLog)) {
                let resultSeq = 0
                _.map(this.filterLog, (val, key) => {
                    resultSeq += 1
                    result[`filter${key + 1}`] = {
                        seq: resultSeq,
                        title: `查詢條件 ${key + 1}`,
                        fill: true
                    }
                    titles._seq.forEach((seqVal) => {
                        resultSeq += 1
                        result[`${seqVal}${key + 1}`] = {
                            seq: resultSeq,
                            title: titles[seqVal],
                            label: val[seqVal]
                        }
                    })
                })
            } else {
                titles._seq.forEach((seqVal, idx) => {
                    result[seqVal] = {
                        seq: idx + 1,
                        title: titles[seqVal],
                        label: this.filterLog[seqVal].slice(0, 7)
                    }
                })
            }
            return result
        },
        excelRows() {
            const result = []
            const data = _.cloneDeep(this.tableShowData)
            data.forEach((d) => {
                let continueRow = {}
                _.keys(d.detail).forEach((key, idx) => {
                    let row = {}
                    // product info
                    if (idx === 0) {
                        const pick = [
                            'serialNumber',
                            'productName',
                            'commissionTotal',
                            'supplier',
                            'rfyp',
                            'onSaleDate',
                            'offSaleDate',
                            'year',
                            'age'
                        ]
                        row = _.pick(d, pick)
                    }
                    // 佣獎 & 總計
                    row.commission = this.commissionSetting[key] || '-'
                    row.total = this.$roundX(d[key]?.subtotal, 0) || '-'
                    // from 首年 to maxPeriod 年
                    for (let i = 1; i <= this.maxPeriod; i++) {
                        let pyb = d[key]?.policyYear
                            ? d[key]?.policyYear[i]
                            : null
                        let fyp = this.$roundX(pyb?.bonus, 0) || '-'
                        let percent = pyb?.rate
                            ? `${this.$roundX(pyb?.rate, 2)}%`
                            : '-'

                        if (key === 'persistency') {
                            if (i === 1) {
                                row.fyp = fyp
                                continueRow.fyp = percent
                            } else {
                                row[`fyp${i}`] = fyp
                                continueRow[`fyp${i}`] = percent
                            }
                        } else {
                            if (i === 1) row.fyp = fyp
                            else row[`fyp${i}`] = fyp
                        }
                    }
                    result.push(row)
                })
                result.push(continueRow)
            })
            return result
        },
        excelMerge() {
            const merge = []
            const infoCounts = 9
            for (let i = 0; i < this.listData.length; i++) {
                for (let j = 0; j < infoCounts; j++) {
                    merge.push({
                        s: { c: j, r: i * 4 + 1 },
                        e: { c: j, r: i * 4 + 4 }
                    })
                }
                for (let k = 0; k < 2; k++) {
                    merge.push({
                        s: { c: infoCounts + k, r: i * 4 + 3 },
                        e: { c: infoCounts + k, r: i * 4 + 4 }
                    })
                }
            }
            return merge
        }
    },
    watch: {
        listData: {
            handler() {
                if (this.listData.length <= 0) return
                let period = 0
                this.listData.forEach((data) => {
                    Object.keys(data.detail).forEach((key) => {
                        let l = 0
                        if (data.detail[key]?.policyYear) {
                            const opt = Object.keys(
                                data.detail[key]?.policyYear
                            ) || [0]
                            l = Math.max(...opt)
                        }
                        if (l > period) period = l
                    })
                })
                this.maxPeriod = Math.min(period, 20)
            },
            deep: true
        }
    },
    data() {
        return {
            maxPeriod: 1,
            commissionSetting: {
                fybRyb: '首續年佣金',
                sab: '服務津貼',
                persistency: '繼續率'
            },
            page: this.$route.path.split('/')[
                this.$route.path.split('/').length - 1
            ],
            descriptions: productDescription
        }
    }
}
</script>

<style lang="scss" scoped>
.table-wrapper {
    margin-top: 20px;
    border-top: 1px solid $sum-row-grey;
    padding-top: 20px;
}

.commission-column {
    display: flex;
    flex-direction: column;
    .commission,
    .service {
        line-height: 40px;
    }
    .continue {
        height: 60px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        .percentage {
            color: $primary-white;
            background: $fourth-blue;
            padding: 0 7.5px;
            border-radius: 5px;
            line-height: 15px;
            font-size: 12px;
        }
    }
}

.fyp-column {
    display: grid;
    grid-template-columns: repeat(v-bind(maxPeriod), 1fr);
    place-items: center right;
    width: 100%;
    gap: 20px;
}

.table-footer {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    text-align: right;
}

.excel-btn {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    border-radius: 3px;
    background: $fourth-blue;
    color: $primary-white;
    cursor: pointer;
}

.table-wrapper {
    :deep(.header) {
        .supplier {
            overflow: hidden;
        }
    }
    :deep(.table-body) {
        .first-line {
            align-items: end;
        }
        .second-line {
            align-items: start;
        }
    }
}
</style>
